(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draw-handler/common/create-draw-handler-base.js') >= 0) return;  svs.modules.push('/components/sport/draw-handler/common/create-draw-handler-base.js');
'use strict';

let createDrawModel;
let isDrawModel;
let getDrawId;
let logger;
let products;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  const _createDrawModel = require('./create-draw-model.es6');
  createDrawModel = _createDrawModel.createDrawModel;
  isDrawModel = _createDrawModel.isDrawModel;
  getDrawId = _createDrawModel.getDrawId;
  logger = require('../server/logger');
  products = trinidad.components.require('utils', 'products');
} else {
  const _createDrawModel = svs.components.sport.drawHandler.common;
  createDrawModel = _createDrawModel.createDrawModel;
  isDrawModel = _createDrawModel.isDrawModel;
  getDrawId = _createDrawModel.getDrawId;
  logger = svs.components.sport.drawHandler.client.logger;
  products = svs.utils.products;
}
const getDrawPath = (productId, drawNumber, query, getFullDraw) => {
  if (getFullDraw) {
    return "/draw/1/".concat(products.getSpecificDrawName(productId), "/draws/").concat(drawNumber);
  }
  return "/draw/1/".concat(products.getSpecificDrawName(productId), "/draws/").concat(drawNumber, "/light").concat(query ? "?".concat(query) : '');
};
const createModelCollection = () => {
  const models = new Map();
  const addModel = drawData => {
    const drawModel = createDrawModel();
    drawModel.setData(drawData);
    if (!isDrawModel(drawModel)) {
      throw new Error('Draw model must be provided');
    }
    const drawId = getDrawId(drawModel.getProductId(), drawModel.getDrawNumber());
    if (models.has(drawId)) {
      throw new Error("Draw already exists: ".concat(drawId));
    }
    models.set(drawId, drawModel);
    return drawModel;
  };
  const setModel = drawModel => {
    if (!isDrawModel(drawModel)) {
      throw new Error('Draw model must be provided');
    }
    models.set(drawModel.getDrawId(), drawModel);
  };
  const getCollection = () => models;
  const getModel = drawId => models.get(drawId);
  const getSerializeModel = () => {
    const drawsObj = {};
    for (const [drawId, draw] of models.entries()) {
      drawsObj[drawId] = {
        model: draw.getData(),
        timestamp: draw.getTimestamp()
      };
    }
    return drawsObj;
  };
  const clear = () => {
    models.clear();
  };
  return {
    addModel,
    setModel,
    getCollection,
    getSerializeModel,
    getModel,
    clear
  };
};
const createSubscriptionModel = (socket, cache) => (productId, drawNumber) => {
  const productPath = getDrawPath(productId, drawNumber);
  const drawId = getDrawId(productId, drawNumber);
  let isSubscribed = false;
  let _subscriptions = [];
  const handleSocketUpdate = _ref => {
    let {
      draw: drawData,
      error: errorData
    } = _ref;
    logger.debug("Socket update on product: ".concat(productId, " draw: ").concat(drawNumber));
    let draw = cache.getModel(drawId);
    if (!drawData) {
      if (errorData && errorData.code !== 404) {
        logger.warn('Got no drawData on socket update');
      }
      return;
    }
    if (!draw) {
      draw = cache.addModel(drawData);
    } else {
      draw.setData(drawData);
    }
    for (const subscription of _subscriptions) {
      subscription(draw);
    }
  };
  const subscribeToSocket = () => {
    if (isSubscribed) {
      return;
    }
    socket.subscribe(productPath, handleSocketUpdate, {
      sendInitialUpdate: true,
      backendFallback: true
    });
    isSubscribed = true;
  };
  const unsubscribeToSocket = () => {
    isSubscribed = false;
    socket.unsubscribe(productPath, handleSocketUpdate);
  };
  const add = callback => {
    if (!isSubscribed) {
      subscribeToSocket();
    }
    _subscriptions.push(callback);
  };
  const remove = callback => {
    _subscriptions = _subscriptions.filter(_callback => _callback !== callback);
    if (!_subscriptions.length) {
      unsubscribeToSocket();
    }
  };
  const unsubscribe = () => {
    _subscriptions.length = 0;
    unsubscribeToSocket();
  };
  return {
    add,
    remove,
    unsubscribe
  };
};
const createSubscriptionHandler = (socket, cache) => {
  const subscriptions = new Map();
  const get = (productId, drawNumber) => {
    const drawId = getDrawId(productId, drawNumber);
    let subObj = subscriptions.get(drawId);
    if (!subObj) {
      subObj = createSubscriptionModel(socket, cache)(productId, drawNumber);
      subscriptions.set(drawId, subObj);
    }
    return subObj;
  };
  const destroy = () => {
    subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    subscriptions.clear();
  };
  return {
    get,
    destroy
  };
};
const createDrawHandlerBase = function () {
  let privateInterface = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  privateInterface.drawCache = createModelCollection();
  const subscriptions = createSubscriptionHandler(privateInterface.socket, privateInterface.drawCache);
  return function () {
    let publicInterface = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    publicInterface.fetch = async function (productId, drawNumber, shouldForceFetch) {
      var _data, _data2;
      let fetchFullDraw = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      const productPath = getDrawPath(productId, drawNumber, 'includes=draw.drawStateId,draw.productId,draw.drawNumber', fetchFullDraw);
      const drawId = getDrawId(productId, drawNumber);
      let draw = privateInterface.drawCache.getModel(drawId);
      if (!shouldForceFetch && draw && !draw.isOutdated()) {
        let hasDrawData = true;
        if (fetchFullDraw) {
          hasDrawData = Object.prototype.hasOwnProperty.call(draw, 'drawEvents') || Object.prototype.hasOwnProperty.call(draw, 'events');
        }
        if (hasDrawData) {
          return draw;
        }
      }

      let data;
      try {
        data = await privateInterface.jupiter(productPath, {
          method: 'GET'
        });
      } catch (err) {
        logger.warn("Failed fetching draw with drawId: ".concat(productId, "_").concat(drawNumber, ". Error: ").concat(err.message, " code: ").concat(err.code));
        throw new Error("Failed fetching draw with drawId: ".concat(productId, "_").concat(drawNumber));
      }

      if (fetchFullDraw) {
        draw = privateInterface.drawCache.getModel(drawId);
      }
      if (draw && (_data = data) !== null && _data !== void 0 && _data.draw) {
        draw.setData(data.draw);
      } else if (!draw && (_data2 = data) !== null && _data2 !== void 0 && _data2.draw) {
        draw = privateInterface.drawCache.addModel(data.draw);
      }
      return draw;
    };
    publicInterface.subscribe = (productId, drawNumber, callback) => {
      subscriptions.get(productId, drawNumber).add(callback);
      const unsubscribe = () => subscriptions.get(productId, drawNumber).remove(callback);
      return unsubscribe;
    };
    publicInterface.unsubscribe = (productId, drawNumber, callback) => {
      subscriptions.get(productId, drawNumber).remove(callback);
    };
    publicInterface.getSubscriptions = () => subscriptions;
    publicInterface.get = (productId, drawNumber) => {
      const drawId = getDrawId(productId, drawNumber);
      const draw = privateInterface.drawCache.getModel(drawId);
      return draw;
    };
    publicInterface.getModel = () => privateInterface.drawCache.getSerializeModel();
    publicInterface.destroy = () => {
      privateInterface.drawCache.clear();
    };
    return publicInterface;
  };
};
if (svs.isServer) {
  module.exports = createDrawHandlerBase;
} else {
  setGlobal('svs.components.sport.drawHandler.common.createDrawHandlerBase', createDrawHandlerBase);
}

 })(window);